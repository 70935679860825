html {
  font-size: 62.5%;
}

body {
  @include font-lato-regular();
  color: $light1;
  background-color: $dark1;
  padding: 0;
  margin: 0;
  font-size: 1.6rem;
}

.hidden {
  display: none !important;
}

.message {
  color: #31c01e;
}

@media #{$screen-laptop} {
  html {
    font-size: 58.59375%;
  }
}

@media #{$screen-tablet} {
  html {
    font-size: 58.59375%;
  }
}

@media #{$screen-phone} {
  html {
    font-size: 54.6875%;
  }
}
