@use '@angular/material' as mat;
@import url('https://fonts.googleapis.com/css?family=Lato:400,700|Roboto:400,500');
// Plus imports for other components in your app.
// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
$stw-photo-typography: mat.define-typography-config(
  $font-family: 'Lato, Roboto, monospace'
);
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core($stw-photo-typography);
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$dark-primary: mat.define-palette(mat.$cyan-palette);
$dark-accent: mat.define-palette(mat.$indigo-palette, A200, A100, A400);
// The warn palette is optional (defaults to red).
$dark-warn: mat.define-palette(mat.$red-palette);
// Create the theme object (a Sass map containing all of the palettes).
$dark-theme: mat.define-dark-theme($dark-primary, $dark-accent, $dark-warn);
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($dark-theme);

@import '~src/sass/global';
