@import '~src/sass/defs';

a,
.anchor {
  color: $lightblue3;
  cursor: pointer;
  text-decoration: none;

  &:visited {
    color: $lightblue3;
  }

  &:hover {
    color: $light0;
    text-decoration: underline;
  }

  &:active {
    color: $lightblue4;
    text-decoration: underline;
  }

  &.current {
    color: $light0;
  }
}
