@import '~src/sass/defs';

mat-bottom-sheet-container.mat-bottom-sheet-container {
  border: 1px solid #bbb;
  border-bottom: none;
  padding: 0;
  background: $dark2;
}

.mat-drawer-container {
  color: $light1;
}

.mat-dialog-container {
  color: $light2;
  border: 1px solid #bbb;
  background: $dark1h;
}

.mat-dialog-actions {
  justify-content: flex-end;
}

div.mat-tab-label {
  height: 40px;
  @include font-robot-medium();
}

div.mat-tab-label-active {
  border-radius: 4px 4px 0 0;
  background: $light2;
  color: $dark1;
  opacity: 1;
}

mat-dialog-title.mat-dialog-title {
  color: $color-main-title;
  margin: 0 0 12px;
  border-bottom: 1px solid #888;
}

mat-tab-header.mat-tab-header {
  border-bottom: 1px solid $light3;
}

.mat-tab-group {
  max-height: 100%;
  &.show-bottom-border {
    border-bottom: 1px solid $light3;
  }
}

.mat-tab-body-content > p:first-child {
  margin-top: 15px;
}

.extra-mat-expansion-panel-no-padding {
  .mat-expansion-panel-body {
    padding: 0;
  }
}

.extra-mat-expansion-panel-lighter-header {
  .mat-expansion-panel-header {
    background: #666666;
    &:active,
    &.mat-expanded:active {
      background: #aaaaaa;
    }
    &.mat-expanded:focus {
      background: #aaaaaa;
    }
  }
}
.mat-drawer-container {
  background-color: unset;
}
