@import '~src/sass/defs';
input[type='submit'],
input[type='button'],
button {
  @include font-robot-medium();
  font-size: 14px;
  font-style: normal;
  background: none;
  box-sizing: border-box;
  background-color: transparent;
  border: none;
}
a.button,
button.button {
  color: $light2;
  line-height: 1.25;
  min-width: 115px;
  text-decoration: none;
  text-transform: none;
  display: inline-block;
  padding: 10px 10px;
  border: 2px solid $light2;
  border-radius: 2px;
  outline: none;
  cursor: pointer;
  text-align: center;
  -webkit-appearance: none;
  transition: color 0.2s linear, background 0.1s linear, opacity 0.2s linear;

  &:hover {
    background: #666;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  &.active {
    color: #000000;
    background: #eeeeee;

    &:hover {
      background: #eeeeee;
    }
  }

  &.primary {
    color: $lightblue3;
    border-color: $lightblue3;
    &:disabled {
      color: $light2;
    }
  }

  &.small {
    min-width: 100px;
    padding: 4px 4px;
  }
}

.bg444 {
  input[type='submit'],
  input[type='button'],
  button,
  a.button,
  .button {
    &:disabled {
      color: #ccc;
      background: #444;
      border-color: #666;
      cursor: not-allowed;
    }
  }
}

.close-button {
  cursor: pointer;
  opacity: 0.8;
}

.clickable {
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.6;
  }
}

.overlay-clickable {
  @include theme-overlay-clickable();
}

.overlay-inline-clickable {
  @include theme-overlay-inline-clickable();
}
