.flex {
  display: flex;
  &.align-center {
    align-items: center;
  }
}

.bottom-border-hidden {
  border-bottom: none !important;
}
