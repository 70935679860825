.admin-sidebar {
  .mat-button-toggle-group {
    flex-wrap: wrap;
    flex-flow: column;
  }

  .mat-button-toggle {
    flex-grow: 1;
    flex-basis: 0;
    + .mat-button-toggle {
      margin-top: 4px;
    }
  }

  .mat-button-toggle-checked {
    background: #eee;
    color: #000;
    font-weight: 600;
  }
  .mat-button-toggle-button {
    min-width: 70px;
    font-size: 0.875em;
    padding: 6px 12px;
  }
  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 1.5;
  }
}

.transparent {
  opacity: 0;
  cursor: auto;
}
