.avatar-photo {
  font-size: 45px;
  width: 45px;
  height: 45px;
  color: $color-placeholder-light;
  @include respond-to(phone) {
    width: 36px;
    height: 36px;
    font-size: 36px;
  }
}
