$font-robot: 'Roboto', sans-serif;
$font-robot-mono: 'Roboto Mono', monospace;
$font-lato: 'Lato', sans-serif;
$screen-width-1000-and-below: 'only screen and (max-width: 1000px)';
$screen-desktop: 'only screen and (min-width: 961px)';
$screen-laptop: 'only screen and (min-width: 768px) and (max-width: 960px)';
$screen-tablet: 'only screen and (min-width: 480px) and (max-width: 767px)';
$screen-phone: 'only screen and (max-width: 479px)';
$screen-desktop-and-laptop: 'only screen and (min-width: 768px)';

$screen-width-581px-and-above: 'only screen and (min-width: 581px)';

$screen-laptop-and-below: 'only screen and (max-width: 960px)';
$screen-width-850px-and-below: 'only screen and (max-width: 850px)';
$screen-tablet-and-below: 'only screen and (max-width: 767px)';
$screen-width-580px-and-below: 'only screen and (max-width: 580px)';
$screen-width-380px-and-below: 'only screen and (max-width: 380px)';

$screen-laptop-and-above: 'only screen and (min-width: 768px)';
$screen-tablet-and-above: 'only screen and (max-width: 480px)';

$light0: #ffffff;
$light1: #e6e6e6;
$light2: #cccccc;
$light3: #b3b3b3;
$light4: #999999;
$light5: #808080;
$dark0: #080808;
$dark1: #212121;
$dark1h: #2e2e2e;
$dark2: #3b3b3b;
$dark3: #545454;
$dark4: #6e6e6e;
$dark5: #888888;
$lightblue0: #ffffff;
$lightblue1: #e6f7ff;
$lightblue2: #b3e6ff;
$lightblue3: #80d4ff;
$lightblue4: #4dc3ff;
$lightblue5: #1ab2ff;
$lightblue6: #0099e6;
$lightblue7: #0077b3;
$lightblue8: #005580;
$lightblue9: #00334d;
$red0: #ffffff;
$red1: #fccfd5;
$red2: #fa9eac;
$red3: #f76e82;
$red4: #f53d59;
$red5: #f20d2f;
$red6: #c20a26;
$red7: #91081c;
$red8: #610513;
$red9: #300309;

$skeleton-color: $dark5;

$color-main-title: #ecd08e;
$color-small-title: #ecd08e;
$color-strong-text: #ecd08e;

$color-failure: red;
$color-success: green;
$color-valid: #42a948;
$color-invalid: #a94442;

$color-placeholder-dark: #444;
$color-placeholder-light: #ddd;
$background-image-spacing: white;

// frame colors
$color-logo: $lightblue3;
$color-footer: $light4;
$color-menu: $light1;
$color-menu-border: $color-menu;

$color-status-bar: $light3;
$background-child-comment: #2e2e2e;

$background-box-item: #444;
$border-box-item: 1px solid #eee;

$color-overlay-clickable: #ffab8f;
$color-overlay-clickable-hover: #eeeeee;
$color-overlay-clickable-active: #b67a67;
$color-overlay-inline-clickable: $color-overlay-clickable;
$color-overlay-inline-clickable-hover: $color-overlay-clickable-hover;
$color-overlay-inline-clickable-active: $color-overlay-clickable-active;
$background-overlay-inline-clickable: $dark2;

$color-filter-available: #44d431;
$color-filter-step-up: #e0d060;

$background-canvas-header: $dark2;

$background-table-header: $dark3;
$color-table-content: #eaeaea;
$color-table-content-hover: #666;

$background-bar1-solid: #dede88;
$background-bar1-gradient: linear-gradient(to left, #ffffcc, #c5c513);

$background-bar2-solid: #73ddef;
$background-bar2-gradient: linear-gradient(to left, #cef7ff, #33b8ce);

$background-bar-active-solid: #fafafa;
$background-bar-active-gradient: linear-gradient(to left, #fafafa, #dadada);

$background-tech-button: #654e2c;

$color-text-in-bar-chart: #000;

@mixin theme-canvas-header {
  color: $color-small-title;
  background: $background-canvas-header;
}

@mixin theme-overlay-clickable {
  color: $color-overlay-clickable;
  &:hover {
    color: $color-overlay-clickable-hover;
  }
  &:active {
    color: $color-overlay-clickable-active;
  }
}

@mixin theme-overlay-inline-clickable {
  color: $color-overlay-inline-clickable;
  background: $background-overlay-inline-clickable;
  &:hover {
    color: $color-overlay-inline-clickable-hover;
  }
  &:active {
    color: $color-overlay-inline-clickable-active;
  }
}

@mixin theme-status-bar {
  color: $color-status-bar;
}

@mixin theme-tech-button {
  border: 1px solid #cea05c;
  background: #654e2c;

  &.current {
    color: #222;
    background: #eee;
  }
}

@mixin theme-section-header-h3 {
  color: #fff;
}

@mixin font-lato-regular {
  font-family: $font-lato;
  font-weight: 400;
}

@mixin font-lato-bold {
  font-family: $font-lato;
  font-weight: 700;
}

@mixin font-robot-regular() {
  font-family: $font-robot;
  font-weight: 400;
}

@mixin font-robot-medium() {
  font-family: $font-robot;
  font-weight: 500;
}

@mixin respond-to($media) {
  // these are relative screen size, not actual device type
  @if $media == desktop {
    @media #{$screen-desktop} {
      @content;
    }
  } @else if $media == laptop {
    @media #{$screen-laptop} {
      @content;
    }
  } @else if $media == tablet {
    @media #{$screen-tablet} {
      @content;
    }
  } @else if $media == phone {
    @media #{$screen-phone} {
      @content;
    }
  }
}

@mixin respond-below($media) {
  @if $media == laptop {
    @media #{$screen-laptop-and-below} {
      @content;
    }
  } @else if $media == tablet {
    @media #{$screen-tablet-and-below} {
      @content;
    }
  } @else if $media == phone {
    @media #{$screen-phone} {
      @content;
    }
  }
}

@mixin respond-above($media) {
  @if $media == desktop {
    @media #{$screen-desktop} {
      @content;
    }
  } @else if $media == laptop {
    @media #{$screen-laptop-and-above} {
      @content;
    }
  } @else if $media == tablet {
    @media #{$screen-tablet-and-above} {
      @content;
    }
  }
}

@mixin responsive($name, $desktop, $laptop, $tablet, $phone) {
  #{$name}: $desktop;

  @media #{$screen-laptop} {
    #{$name}: $laptop;
  }

  @media #{$screen-tablet} {
    #{$name}: $tablet;
  }

  @media #{$screen-phone} {
    #{$name}: $phone;
  }
}

// Adjusts values based on viewport width (browser width). Good for padding and margin.
@mixin vw-spacing($name, $desktop) {
  $laptop: ();
  $tablet: ();
  $phone: ();

  $laptop: append($laptop, nth($desktop, 1) * 1);
  $tablet: append($tablet, nth($desktop, 1) * 0.8);
  $phone: append($phone, nth($desktop, 1) * 0.8);

  @if length($desktop) >= 2 {
    $laptop: append($laptop, nth($desktop, 2) * 0.8);
    $tablet: append($tablet, nth($desktop, 2) * 0.6);
    $phone: append($phone, nth($desktop, 2) * 0.5);
  }

  @if length($desktop) >= 3 {
    $laptop: append($laptop, nth($desktop, 3) * 1);
    $tablet: append($tablet, nth($desktop, 3) * 0.8);
    $phone: append($phone, nth($desktop, 3) * 0.8);
  }

  @if length($desktop) >= 4 {
    $laptop: append($laptop, nth($desktop, 4) * 0.8);
    $tablet: append($tablet, nth($desktop, 4) * 0.6);
    $phone: append($phone, nth($desktop, 4) * 0.5);
  }

  @include responsive($name, $desktop, $laptop, $tablet, $phone);
}

@mixin vw-spacing-horizontal($name, $desktop) {
  @include responsive(
    $name,
    $desktop,
    $desktop * 1,
    $desktop * 0.8,
    $desktop * 0.8
  );
}

@mixin vw-spacing-vertical($name, $desktop) {
  @include responsive(
    $name,
    $desktop,
    $desktop * 0.8,
    $desktop * 0.6,
    $desktop * 0.5
  );
}

@mixin vw-padding($desktop) {
  @include vw-spacing('padding', $desktop);
}

@mixin vw-padding-top($desktop) {
  @include vw-spacing-horizontal('padding-top', $desktop);
}

@mixin vw-padding-right($desktop) {
  @include vw-spacing-vertical('padding-right', $desktop);
}

@mixin vw-padding-bottom($desktop) {
  @include vw-spacing-horizontal('padding-bottom', $desktop);
}

@mixin vw-padding-left($desktop) {
  @include vw-spacing-vertical('padding-left', $desktop);
}

@mixin vw-margin($desktop) {
  @include vw-spacing('margin', $desktop);
}

@mixin vw-margin-top($desktop) {
  @include vw-spacing-horizontal('margin-top', $desktop);
}

@mixin vw-margin-right($desktop) {
  @include vw-spacing-vertical('margin-right', $desktop);
}

@mixin vw-margin-bottom($desktop) {
  @include vw-spacing-horizontal('margin-bottom', $desktop);
}

@mixin vw-margin-left($desktop) {
  @include vw-spacing-vertical('margin-left', $desktop);
}

@mixin vw-font-size($desktop) {
  @include responsive(
    'font-size',
    $desktop,
    $desktop * 0.9375,
    $desktop * 0.9375,
    $desktop * 0.875
  );
}

@mixin vw-large-font-size($desktop) {
  @include responsive(
    'font-size',
    $desktop,
    $desktop * 0.875,
    $desktop * 0.875,
    $desktop * 0.75
  );
}
