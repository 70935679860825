input[type='text'],
input[type='password'],
input[type='email'],
input[type='url'],
input[type='date'],
input[type='tel'],
.input-text,
select {
  padding: 11px 15px 12px 15px;
  font-size: 14px;
  line-height: 15px;
  margin: 0;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid #dddddd;
  outline: none;
  transition: border-color linear 0.3s;
  box-sizing: border-box;
  -webkit-appearance: none;
  border-radius: 0;
  background-color: $dark2;
  color: #ffffff;

  &::placeholder {
    color: #ccc;
  }
}

textarea {
  @include font-lato-regular();
  font-size: 14px;
  outline: none;
  line-height: 26px;
  background-color: $dark2;
  color: #ffffff;
  border-color: #dddddd;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid #e1e1e1;
  padding: 0.6em 15px;
  box-sizing: border-box;
  box-shadow: 1px 1px 3px #eeeeee;
  border-radius: 0px;

  &::placeholder {
    color: #ccc;
  }
}

@media #{$screen-phone} {
  input[type='text'],
  input[type='password'],
  input[type='email'],
  input[type='url'],
  input[type='date'],
  input[type='tel'],
  .input-text,
  select {
    &::placeholder {
      letter-spacing: 0px;
    }
  }
}
