.pros-icon {
  color: #53ce5a;
}

.check-icon {
  color: #53ce5a;
}

.cross-icon {
  color: #e75451;
}