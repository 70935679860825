h1.section-header {
  @include vw-large-font-size(28px);
}

h2.section-header {
  @include font-robot-medium();
  letter-spacing: 1px;
  text-shadow: 1px 1px 2px #56563d;
  @include vw-large-font-size(24px);
  color: $light1;
  @include vw-padding((12px 0));
  margin-bottom: 8px;
}

h3.section-header {
  @include font-robot-regular();
  color: $light1;
  @include vw-large-font-size(20px);
  @include vw-padding((6px 0));
  letter-spacing: 1px;
}

h4.section-header {
  @include font-robot-medium();
  @include vw-large-font-size(18px);
  letter-spacing: 0.5px;
}

.section-body {
  @include vw-margin-bottom(24px);

  p {
    @include respond-to(desktop) {
      padding-right: 8%;
      font-size: 106.25%;
      letter-spacing: 0.5px;
    }
    color: $light1;
    @include vw-margin-bottom(16px);
  }

  ul {
    @include respond-to(desktop) {
      font-size: 17px;
    }
    @include vw-padding((0 0 0 25px));
    @include vw-margin-bottom(15px);
  }

  li {
    @include vw-margin-bottom(4px);
  }

  .brief-wrapper {
    @include respond-above(laptop) {
      padding: 15px;
    }
    background: $dark2;
    max-width: 860px;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    @include vw-margin-bottom(20px);
  }
}

.section-margin {
  height: 1px;
  @include vw-margin-bottom(24px);
}

.section-ending {
  height: 1px;
  @include vw-margin-bottom(48px);
}

.paragraph-header {
  @include font-robot-regular();
  letter-spacing: 0.5px;
  color: $color-small-title;
}

.paragraph-spacing {
  @include vw-margin-bottom(16px);
}

.operation {
  font-family: $font-robot;
  color: #ecd08e;
}
